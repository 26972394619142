<template>
	<div v-if="avaliacao">
	    <h1>RDI - Relatório de Desempenho Individual</h1>
	    <p v-if="avaliacao.equipe_trabalho.gratificacao">
	    	O presente formulário é aplicável para as gratificações: 
	    	{{ avaliacao.rdi.gratificacoes.map(gratificacao => gratificacao.sg_gratificacao).join(', ')}}.
	    </p>

		<v-alert v-if="avaliacao.equipe_trabalho.gratificacao && avaliacao.equipe_trabalho.gratificacao.sg_gratificacao == 'GDAPS'" type="warning" prominent>
            <div class="text-h6">
                <strong>Senhor(a) ATPS,</strong>
            </div>
            <span>
        		esta avaliação é apenas gerencial e não se aplicará para contagem de pontos para o pagamento da GDAPS, em razão do artigo 16-A da Lei n. 12.094/2009.
            </span>
        </v-alert>

		<v-alert v-if="avaliacaoFoiConsolidada" type="warning" prominent>
            <div class="text-h6">
                <strong>Avaliação já foi consolidada</strong>
            </div>
            <span>
        		Não é possível editar avaliações que já foram consolidadas.
            </span>
        </v-alert>
        <v-card class="pa-3" color="grey lighten-4">
        	<v-row dense>
        		<v-col cols=8>
        			<v-card class="mx-2 mb-2 mt-4 pt-2 pb-4 px-4">
        				<h3 class="mt-3">Servidor avaliado</h3>
        				<v-row class="mt-3">
        					<v-col cols="12" class="mx-0">
		                        <v-form class="pa-0" ref="form_servidor_nome" v-on:submit.prevent>
		                            <v-text-field  ref="nome" dense hide-details label="Nome" v-model="avaliacao.equipe_trabalho.servidor.nm_servidor" required readonly>
		                            </v-text-field>
		                        </v-form>
		                    </v-col>
        				</v-row>
        				<v-row>
        					<v-col cols="6" class="mx-0">
		                        <v-form class="pa-0" ref="form_servidor_siape" v-on:submit.prevent>
		                            <v-text-field  ref="siape" dense hide-details label="SIAPE" v-model="avaliacao.equipe_trabalho.servidor.nr_siape" required readonly>
		                            </v-text-field>
		                        </v-form>
		                    </v-col>
		                    <v-col v-if="avaliacao.equipe_trabalho.gratificacao" cols="6" class="mx-0">
		                        <v-form class="pa-0" ref="form_servidor_gratificacao" v-on:submit.prevent>
		                            <v-text-field  ref="gratificacao" dense hide-details label="Gratificação" v-model="avaliacao.equipe_trabalho.gratificacao.sg_gratificacao" required readonly>
		                            </v-text-field>
		                        </v-form>
		                    </v-col>
        				</v-row>
        			</v-card>
        			<v-card class="mx-2 mb-2 mt-4 pt-2 pb-4 px-4">
        				<h3 class="mt-3">Avaliação</h3>
						<v-form class="mt-3">
	        				<ol>
	        					<li v-for="(fator,index) in avaliacao.rdi.fatores">	
		        						{{ fator.nm_fator.toUpperCase()}} 
		        						<v-chip
					          				small
					          				label
		      								color="primary"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									Peso = {{ fator.pivot.nr_peso }}
		      								</span>
											  
		   								</v-chip>
		   							
	   								<p><small>{{ fator.ds_fator }}</small></p>
									
	   								<v-select
										v-model="avaliacao.fatores[index].pivot.nr_nota"
										:items="conceitosAvaliacao"
										item-value="value"
										item-text="text"
										:disabled="avaliacaoFoiConsolidada"
										dense
										outlined
								  		@change="refreshSendButton()"
								  		placeholder="Selecione uma opção"
									>
									</v-select>
	        					</li>
	        				</ol>
							<v-btn
								v-if="avaliacaoFoiConsolidada" 
  								disabled
							>
								Não é possível editar. Esta avaliação já foi consolidada
							</v-btn>

	        				<v-btn 
								v-else
	        					@click="submitAvaliacao()"
  								color="success"
								  :disabled="sendButtonDisabled"
							>
								Enviar avaliação
							</v-btn>
	        			</v-form>
        			</v-card>
        		</v-col>
        		<v-col cols=4 v-if="avaliacao.equipe_trabalho.ds_meta_individual">
        			<v-card class="mx-2 mb-2 mt-4 py-2 px-4">
        				<h3 class="mt-3">Meta individual</h3>
        				<p class="mt-2 text-body-2">{{ avaliacao.equipe_trabalho.ds_meta_individual }}</p>
        			</v-card>
        		</v-col>
        	</v-row>
        	
		</v-card>
	</div>
</template>

<script>
	import { getAvaliacao, updateAvaliacao, createAvaliacao, getAvaliacaoCadastradaConsolidada } from '../api/avaliacao';
    export default {
        name: 'AvaliacoesDetalhar',
        data() {
	        return {
				sendButtonDisabled: true,
	            avaliacao: null,
	            conceitosAvaliacao: [
	            	{
	            		value: 1,
	            		text: 'Não atende às expectativas - 1 ponto'
	            	},
	            	{
	            		value: 2,
	            		text: 'Atende pouco às expectativas - 2 pontos'
	            	},
	            	{
	            		value: 3,
	            		text: 'Atende satisfatoriamente às expectativas - 3 pontos'
	            	},
	            	{
	            		value: 4,
	            		text: 'Atende muito às expectativas - 4 pontos'
	            	},
	            	{
	            		value: 5,
	            		text: 'Atende totalmente às expectativas - 5 pontos'
	            	},
	            ],
				avaliacaoFoiConsolidada : false
	        };
	    },
	    created() {
	        getAvaliacao(this.$route.params.id)
	            .then(response => {
	                this.avaliacao = response.data.data;
					if (this.avaliacao.fatores.length === 0) {
						this.avaliacao.rdi.fatores.map(fator => {
							let newFator = JSON.parse(JSON.stringify(fator));
							newFator.pivot.nr_nota = 0;
							this.avaliacao.fatores.push(newFator);	
						});
					}
					this.refreshSendButton();
	            });

			getAvaliacaoCadastradaConsolidada(this.$route.params.id)
	            .then(response => {
	                this.avaliacaoFoiConsolidada = response.data.data;
	            });
	    },
	    methods: {
			refreshSendButton(){
				if (this.avaliacao.fatores.filter(fator => fator.pivot.nr_nota === 0).length === 0) {
					this.sendButtonDisabled = false;
				}
			},
	    	submitAvaliacao() {
	    		var submit = JSON.parse(JSON.stringify(this.avaliacao));
	    		submit.st_pendente = false; 
	    		delete submit.rdi;
		
				let fatores = this.avaliacao.fatores.map(fator => {
	    			return {
	    				id_fator: fator.id_fator,
	    				nr_nota: fator.pivot.nr_nota,
	    				nr_peso: this.avaliacao.rdi.fatores.find(fatorRdi => fatorRdi.id_fator == fator.id_fator).pivot.nr_peso
	    			}
	    		});

	    		submit.fatores = fatores.flat();

				if(this.avaliacao.id_avaliacao == undefined){
					createAvaliacao(JSON.stringify(submit))
                    .then(response => {
                        this.avaliacao = response.data.data;
                        this.$router.push(
	                        {
	                            name: 'Realizar Avaliação',
	                        },
	                        () => this.$store.dispatch('alertas/show', {
	                            tipo: 'Sucesso',
	                            titulo: 'Avaliação enviada com sucesso!',
	                        })
                        );
                    });
				} else {
					updateAvaliacao(this.avaliacao.id_avaliacao, JSON.stringify(submit))
                    .then(response => {
                        this.avaliacao = response.data.data;
                        this.$router.push(
	                        {
	                            name: 'Realizar Avaliação',
	                        },
	                        () => this.$store.dispatch('alertas/show', {
	                            tipo: 'Sucesso',
	                            titulo: 'Avaliação enviada com sucesso!',
	                        })
                        );
                    });
				}
	    	}
	    }
    } 
</script>
